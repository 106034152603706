module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cherrie Lam | Product Designer","short_name":"Cherrie Lam","description":"Product designer focused on inclusive, community-centred digital tools and experiences. Currently Design Lead at PeaceGeeks, creating digital tools to support immigrant and displaced communities. In Vancouver — traditional, unceded lands of the xʷməθkʷəy̓əm, Sḵwx̱wú7mesh, Stó:lō and Səl̓ílwətaʔ/Selilwitulh Nations.","start_url":"/","background_color":"#efe8e3","theme_color":"#efe8e3","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3eaea0bc7561927bce35c6cbd0030923"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
